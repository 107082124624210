/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import ExtraContent from 'elements/ExtraContent'
import CallToActions from 'elements/CallToActions'
import CtaBlok from 'components/actions/CtaBlok'
import Breadcrumb from 'elements/Breadcrumb'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'
import ParseContent from 'shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  h2 {
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size.ml};
    margin-bottom: 0;
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  ul {
    li {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  }

  table {
    margin-bottom: 2rem;

    a {
      font-size: ${(props) => props.theme.font.size.sm};
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, postNieuws, seo },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO seo={seo} customTitle={title} />

      <HeroDefault fields={postNieuws.nieuwsbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-7">
            <Content content={postNieuws.nieuwsstory.description} />
          </div>
          <div className="col-lg-5 pt-lg-0 pt-4">
            <CallToActions actions={postNieuws.nieuwscallToAction} />
          </div>
        </div>
      </div>

      {postNieuws.ctaOnderaan.relation !== null && (
        <CtaBlok id={postNieuws.ctaOnderaan.relation[0].databaseId} />
      )}

      <ExtraContent content={postNieuws.nieuwsrelation} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPost(databaseId: { eq: $wordpress_id }) {
      ...PostFrag
    }
  }
`

export default PageTemplate
