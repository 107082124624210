import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'

import Info from 'img/info.inline.svg'
import Hexagon from 'img/hexagon.svg'

const StyledBlok = styled.div`
  background: ${(props) => props.theme.color.face.main};
`

const Content = styled(ParseContent)`
  & h2 {
    color: ${(props) => props.theme.color.text.light};
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  & p {
    color: ${(props) => props.theme.color.text.light};
    margin-bottom: 1.5rem;

    @media (max-width: 992px) {
      & br {
        display: none;
      }
    }
  }

  & li {
    color: ${(props) => props.theme.color.text.light};
  }
`

const Icon = styled(Info)`
  & path {
    color: #fff;
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  & a {
    @media (min-width: 992px) {
      padding: 13px 50px;
    }
  }
`

const HexagonDiv = styled.div`
  background: url(${Hexagon}) no-repeat;
  height: 310px;
  width: 357px;
  position: relative;
`

const Svg = styled.svg`
  @media (min-width: 1200px) {
    position: absolute;
    left: 660px;
  }
`

const CtaBlok = ({ className, id }) => {
  const { cta } = useStaticQuery(graphql`
    {
      cta: allWpCtaNieuws {
        edges {
          node {
            slug
            title
            databaseId
            ctaBlokNieuws {
              ctaBlok {
                description
                info
                button {
                  title
                  url
                }
                image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(quality: 100, width: 702)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filteredCta = cta.edges.filter((post) => post.node.databaseId === id)

  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-lg-8 px-lg-0">
          {filteredCta.map((item, index) => (
            <StyledBlok
              className={`${
                item.node.ctaBlokNieuws.ctaBlok.image
                  ? 'd-flex flex-wrap align-items-center justify-content-between py-0 pr-0 ps-4 text-left'
                  : 'p-5 text-center'
              } position-relative`}
            >
              <div
                className={`${
                  item.node.ctaBlokNieuws.ctaBlok.image && 'col-lg-9 py-3 mt-1'
                } `}
              >
                <Content
                  content={item.node.ctaBlokNieuws.ctaBlok.description}
                />
                <StyledButtonDefault
                  to={item.node.ctaBlokNieuws.ctaBlok.button.url}
                >
                  {item.node.ctaBlokNieuws.ctaBlok.button.title}
                </StyledButtonDefault>
                <div
                  className={`d-flex flex-wrap mt-3 ${
                    !item.node.ctaBlokNieuws.ctaBlok.image &&
                    'justify-content-center'
                  }`}
                >
                  <Icon />
                  <Content
                    content={item.node.ctaBlokNieuws.ctaBlok.info}
                    className="ps-3 text-white"
                  />
                </div>
              </div>
              {item.node.ctaBlokNieuws.ctaBlok.image &&
                item.node.ctaBlokNieuws.ctaBlok.image.localFile && (
                  <Svg
                    id="image-fill"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="100%"
                    height="300"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <pattern
                        id="image-bg"
                        x="0"
                        y="0"
                        height="300"
                        width="300"
                        patternUnits="userSpaceOnUse"
                      >
                        <image
                          width="300"
                          height="300"
                          xlinkHref={
                            item.node.ctaBlokNieuws.ctaBlok.image.localFile
                              .publicURL
                          }
                        ></image>
                      </pattern>
                    </defs>
                    <polygon
                      class="hex"
                      points="300,150 225,280 75,280 0,150 75,20 225,20"
                      fill="url('#image-bg')"
                    ></polygon>
                  </Svg>
                )}
            </StyledBlok>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CtaBlok
