import React, { useState, useLayoutEffect, useEffect } from 'react'
import Plaatjie from '@ubo/plaatjie'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import mail from 'img/icon/mail_white.svg'
import phone from 'img/icon/phone_white.svg'

// Components
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

const StyledAskAdvice = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.contrast};
  padding: 30px 25px 30px 40px;
  width: 400px;
  margin-top: -3rem;

  @media (max-width: 991px) {
    margin-top: 0;
  }
`

const StyledImg = styled(Plaatjie)`
  height: 128px;
  width: 112px;

  @media screen and (max-width: 400px) {
    height: 100px;
    width: 80px;
  }
`

const Content = styled(ParseContent)`
  h2 {
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size.ml};
    color: ${(props) => props.theme.color.text.light};
  }

  p {
    color: ${(props) => props.theme.color.text.light};
  }
`

const Name = styled.div`
  color: ${(props) => props.theme.color.face.light};
  font-weight: ${(props) => props.theme.font.weight.xl};
  line-height: 16px;
`

const AskAdvice = ({ className }) => {
  const { ctas } = useStaticQuery(graphql`
    {
      ctas: wpCalltoaction(databaseId: { eq: 1435 }) {
        ...AskAdviceFrag
      }
    }
  `)

  const [ref, isVisible] = useInView({
    threshold: 0.3,
  })

  const [view, setView] = useState(false)
  const [random, setRandom] = useState(0)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  useEffect(() => {
    setRandom(Math.round(Math.random() * 1))
  }, [])

  return (
    <StyledAskAdvice
      ref={ref}
      transition={{ type: 'spring' }}
      className={`${className ? `${className}` : ``}`}
    >
      <Content content={ctas.ctaAdviesNodig.needadvicedescription} />
      <div className="row pt-2">
        <div className="col-5">
          <StyledImg
            loading="eager"
            image={ctas.ctaAdviesNodig.needadviceimage}
            alt=""
          />
        </div>
        <div className="col-7 ps-0 d-flex align-items-center">
          <div>
            <Name className="mb-2 d-flex align-items-center">
              {ctas.ctaAdviesNodig.needadvicename}
            </Name>
            <div className="mb-1 d-flex align-items-center">
              <img className="me-3" src={mail} alt="" />
              <CustomLink
                external
                to={ctas.ctaAdviesNodig.needadvicemaillink.url}
              >
                <p className="mb-0 color-light">
                  {ctas.ctaAdviesNodig.needadvicemaillink.title}
                </p>
              </CustomLink>
            </div>
            <div className="d-flex align-items-center">
              <img className="me-3" src={phone} alt="" />
              <CustomLink
                external
                to={ctas.ctaAdviesNodig.needadvicephonelink.url}
              >
                <p className="mb-0 color-light">
                  {ctas.ctaAdviesNodig.needadvicephonelink.title}
                </p>
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
    </StyledAskAdvice>
  )
}

export default AskAdvice
