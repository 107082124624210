import React from 'react'

// Actions
import AskAdvice from 'components/actions/AskAdvice'
import CtaBlok from 'components/actions/CtaBlok'

// Third Party
import styled from 'styled-components'

const StyledCallToActions = styled.div``

const CallToActions = ({ actions, className }) => {
  return (
    <StyledCallToActions className={`${className ? `${className}` : ``}`}>
      {actions.relation.map((r) => (
        <div className="d-flex justify-content-center justify-content-lg-end">
          {
            {
              'advies-nodig-adriaan': <AskAdvice className="mt-lg-n5" />,
              'advies-nodig-niek': <AskAdvice className="mt-lg-n5" />,
            }[r.slug]
          }
        </div>
      ))}
    </StyledCallToActions>
  )
}

export default CallToActions
